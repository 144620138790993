<template>
  <div class="comment-ref-container">
    <a-modal v-model="visible" :footer="null">
      <img :src="url" width="100%" />
    </a-modal>
    <div class="comment-ref-container__body">
      <div class="comment-ref-container__body--name">
        {{ name }}
      </div>
      <div class="comment-ref-container__body--image" v-if="(image != null)">
        <img :src="image" width="300px" v-on:click="showImage"/>
      </div>
      <div class="comment-ref-container__body--message">
        {{ message }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    image: String,
    message: String
  },
  data() {
    return {
      visible: false,
      url: null
    }
  },
  methods: {
    showImage() {
      this.visible = true;
      this.url = this.image
    }
  }
}
</script>
<style scoped>
.comment-ref-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 15px;
  border-radius: 10px;
  background-color: #f5f5f5;
  border-color: #bbbbbb;
}

.comment-ref-container__icon {
  margin-right: 20px;
  color: #333333;
}

.comment-ref-container__icon img {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.comment-ref-container__body--name {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.comment-ref-container__body--name img {
  width: 15px;
  margin-left: 10px;
}

.comment-ref-container__body--date {
  font-size: 14px;
  margin-bottom: 10px;
  color: #999999;
}

.comment-ref-container__body--image {
  margin-bottom: 10px;
}

.comment-ref-container__body--message {
  font-size: 14px;
  color: #333333;
  width: 80%;
}
</style>
