<template>
  <div class="comment-container">
    <div class="comment-container-item" ref="commentList">
      <commentItemVue v-for="item of data" :key="item.id" 
        :id="item.id"
        :avatar="((item.user || {}).avatar || {}).url"
        :name="(item.user || {}).fullname || 'Unknown'"
        :date="item.created_at"
        :image="item.image.url"
        :message="item.content"
        :reference="item.reference"
        @onReply="handleReply"
      />
    </div>
    <commentInputVue ref="commentInput"
      :participants="participants"
      @onSend="handleSend"
    />
  </div>
</template>
<script>
import commentItemVue from './comment-item.vue';
import commentInputVue from './comment-input.vue';

export default {
  props: {
    data: Array,
    participants: Array
  },
  components: {
    commentItemVue,
    commentInputVue
  },
  methods: {
    scrollToBottom() {
      const commentList = this.$refs.commentList;
      commentList.scrollTop = commentList.scrollHeight;
    },

    smoothScrollToBottom() {
      const commentList = this.$refs.commentList;
      commentList.scroll({ top: commentList.scrollHeight, behavior: 'smooth' })
    },

    handleSend(data) {
      this.$emit('onSendComment', data);
    },

    handleReply(data) {
      this.$refs.commentInput.initReply(data);
    }
  }
}
</script>
<style scoped>
.comment-container {
  position: relative;
}
.comment-container-item {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
