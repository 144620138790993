<template>
  <div class="comment-item-container">
    <a-modal v-model="visible" :footer="null">
      <img :src="url" width="100%" />
    </a-modal>
    <div class="comment-item-container__icon">
      <img :src="avatar" v-if="avatar" />
      <span class="fe fe-user" style="font-size: 30px" v-else></span>
    </div>
    <div class="comment-item-container__body">
      <div class="comment-item-container__body--name">
        {{ name }}
        <div class="reply-container" v-on:click="onClickReply">
          <img src="../../../assets/reply-solid.svg" />
        </div>
      </div>
      <commentRefVue v-if="reference"
        :name="reference.user.fullname"
        :image="reference.image.url"
        :message="reference.content"
      />
      <div class="comment-item-container__body--date">
        {{ date }}
      </div>
      <div class="comment-item-container__body--image" v-if="(image != null)">
        <img :src="image" width="300px" v-on:click="showImage"/>
      </div>
      <div class="comment-item-container__body--message">
        {{ message }}.
      </div>
    </div>
  </div>
</template>
<script>
import commentRefVue from './comment-ref.vue';
export default {
  props: {
    id: Number,
    avatar: String,
    name: String,
    date: String,
    image: String,
    message: String,
    reference: Object
  },
  components: {
    commentRefVue
  },
  data() {
    return {
      visible: false,
      url: null
    }
  },
  methods: {
    onClickReply() {
      const data = {
        id: this.id,
        name: this.name,
        image: this.image,
        message: this.message
      }
      this.$emit('onReply', data)
    },

    showImage() {
      this.visible = true;
      this.url = this.image
    }
  }
}
</script>
<style scoped>
.comment-item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.comment-item-container__icon {
  margin-right: 20px;
  color: #333333;
}

.comment-item-container__icon img {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.comment-item-container__body--name {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.comment-item-container__body--name img {
  width: 15px;
  margin-left: 10px;
}

.comment-item-container__body--date {
  font-size: 14px;
  margin-bottom: 10px;
  color: #999999;
}

.comment-item-container__body--image {
  margin-bottom: 10px;
}

.comment-item-container__body--message {
  font-size: 14px;
  color: #333333;
  width: 80%;
}

.reply-container {
  height: 10px;
  width: 10px;
  cursor: pointer;
}
</style>
