<template>
  <div>
    <div class="air__utils__heading">
      <div class="row">
        <div class="col-md-12">
          <h5>
            <span class="mr-3">Detail Forum</span>
            <div class="pull-right">
              <button class="btn btn-with-addon mr-auto btn-primary" v-on:click="onClickListParticipant">
                <span class="btn-addon">
                  <i class="btn-addon-icon fe fe-user"></i>
                </span>
                Daftar Pengguna
              </button>
              <a-modal v-model="visible" :footer="null">
                <div class="participant-container">
                  <div class="participant-item" v-for="user of participants" :key="user.id">
                    <div class="participant-avatar">
                      <img :src="user.avatar.url" v-if="user.avatar.url" />
                      <span class="fe fe-user" style="font-size: 30px" v-else></span>
                    </div>
                    <p>{{ user.fullname }}</p>
                  </div>
                </div>
              </a-modal>
            </div>
          </h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">
                {{ title }}
              </h5>
            </div>
          </div>
          <div class="card-body row">
            <div class="card-img-left col-md-4" v-if="image">
              <img :src="image" class="width-100"/>
            </div>
            <div class="card-text p-4" style="color: rgba(0, 0, 0, 0.85)">
              {{ body }}
            </div>
          </div>
          <a-form :form="form" @submit="handleSubmit" style="display: none">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <a-form-item label="Balas forum" class="col-md-12">
                    <quill-editor
                        theme="snow"
                        class="editor"
                        ref="myTextEditor"
                        :options="editorOption"
                        @change="onEditorChange"
                        v-model="content"
                        :value="content"
                    />
                  </a-form-item>
                  <a-form-item label="Upload Gambar" class="col-md-3" style="display: none">
                    <a-upload
                      name="image"
                      listType="picture-card"
                      class="avatar-uploader"
                      :showUploadList="false"
                      :beforeUpload="() => { return false }"
                      @change="onFileChange">
                      <img v-if="imageUrl"
                        :src="imageUrl"
                        alt="image"
                        class="img-fluid"
                      />
                      <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'"/>
                        <div class="ant-upload-text">Pilih Foto</div>
                      </div>
                    </a-upload>
                  </a-form-item>
                  <div class="col-md-12" style="display: none">
                    <button type="submit" class="btn btn-primary btn-with-addon">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-send"/>
                    </span>
                      Balas
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </a-form>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Semua Komentar</h5>
            </div>
          </div>
          <div class="card-body">
            <commentsVue ref="comments"
              :data="data"
              :participants="participants" 
              @onSendComment="handleSendComment"
            />
            <div class="air__utils__scrollTable" style="display: none">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="data"
                  :pagination="pagination"
                  :loading="loading"
              >
                <span slot="image" slot-scope="image">
                  <img :src="image.url" class="width-64" v-if="image.url"/>
                  <a-avatar icon="question" v-else/>
                </span>
                <span slot="full_name" slot-scope="full_name">
                  <p v-if="full_name">{{ full_name }}</p>
                  <p v-else> - </p>
                </span>
                <span slot="time" slot-scope="time">
                  <p v-if="time">{{ time }}</p>
                  <p v-else> - </p>
                </span>
                <span slot="comment" slot-scope="comment">
                  <p v-if="comment">{{ comment.replace(list_comment, "") }}</p>
                  <p v-else> - </p>
                </span>
                <span slot="image" slot-scope="image">
                  <img v-if="image.url" :src="image.url" class="width-64"/>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commentsVue from "./comments.vue";
import debounce from "lodash/debounce";
import hljs from 'highlight.js';
import {
  getDetailForum, 
  getListComment,
  giveComment
} from "@/api/forum.api";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const columns = [
  {
    title: "Avatar Pengguna",
    dataIndex: "user.avatar",
    scopedSlots: {customRender: 'image'}
  },
  {
    title: "Nama",
    dataIndex: "user.fullname",
    scopedSlots: {customRender: 'full_name'}
  },
  {
    title: "Komentar",
    dataIndex: "content",
    scopedSlots: {customRender: 'comment'}
  },
  {
    title: "Waktu Komentar",
    dataIndex: "created_at",
    scopedSlots: {customRender: 'time'}
  },
  {
    title: "Gambar",
    dataIndex: "image",
    scopedSlots: {customRender: 'image'}
  },
];

export default {
  props: {
    id: String
  },
  components: {
    commentsVue
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'font': []}],
            [{'color': []}, {'background': []}],
            [{'align': []}],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        },
      },
      form: this.$form.createForm(this),
      data: [],
      participants: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      loading: false,
      columns,
      body: ``,
      image: '',
      title: '',
      content: ``,
      list_comment: '',
      imageUrl: '',
      thumbnail: null,
      visible: false
    };
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value
    },
  },
  mounted() {
    this.fetchData();
    this.fetchDetailForum();
  },
  methods: {
    handleSendComment: function(data) {
      this.$nprogress.start();
      const formData = new FormData();
      formData.set("content", data.comment);
      if(data.refid) {
        formData.set("reference_id", data.refid);
      }
      if(data.file) {
        formData.set("image", data.file);
      }
      this.submitData(formData, true);
    },
    onEditorChange: debounce(function (value) {
      this.content = value.html
    }, 466),
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          formData.set("content", this.content);
          if (this.thumbnail != null) {
            formData.set("image", this.thumbnail);
          }
          if(!this.content){
            this.$notification["warning"]({
              message: "Invalid",
              description: "Isi komentar tidak boleh kosong!"
            });
            this.$nprogress.done();
          }else {
            this.submitData(formData);
            this.content = ``;
          }
        }
      })
    },
    async fetchData(params = {}, smoothScroll = false) {
      try {
        const limit = 1000;
        this.loading = true;
        params = Object.assign(params, { limit })
        const commentsResponse = await getListComment(params, this.id);
        // const participantsResponse = await getListParticipant(this.id);
        if (commentsResponse.status == 200) {
          this.data = commentsResponse.data.results;
          this.loading = false;
          setTimeout(() => {
            if(smoothScroll) {
              this.$refs.comments.smoothScrollToBottom();
            } else {
              this.$refs.comments.scrollToBottom();
            }
          }, 100);
        }

        // if(participantsResponse.status == 200) {
        //   this.participants = participantsResponse.data;
        // }
      } catch (error) {/**/
      }
    },
    async fetchDetailForum() {
      try {
        let response = await getDetailForum(this.id);
        if (response) {
          this.body = (response.data.body).replace(/<\/?[^>]+(>|$)/g, "")
          this.image = response.data.image.url
          this.title = response.data.title
          this.participants = response.data.comment_users
        }
      } catch (e) {
        /**/
      }
    },
    async submitData(params, smoothScroll = false) {
      try {
        let response = await giveComment(params, this.id);
        if (response) {
          this.thumbnail = null
          this.imageUrl = ''
          this.$notification["success"]({
            message: "Sukses",
            description: "Berhasil mengirimkan komentar"
          });
          // this.handleTableChange()
        }
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
      await this.fetchData({}, smoothScroll)
    },
    beforeUpload() {
      return false;
    },
    onFileChange(info) {
      if (this.checkImageUpload(info.file)) {
        getBase64(info.file, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
        this.thumbnail = info.file;
      }
    },
    checkImageUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
      var validFormat = true;
      if (!isJpgOrPng) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Format Foto tidak Valid"
        });
        validFormat = false;
      }
      const isLt5M = file.size / 1024 <= 20480;
      if (!isLt5M) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Size terlalu besar!"
        });
        validFormat = false;
      }
      return validFormat;
    },
    onClickListParticipant() {
      this.visible = true;
    }
  }
};
</script>
<style scoped>
.width-64 {
  width: 64px;
}
.width-100 {
  width: 100%;
}

.participant-container {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: none;
}

.participant-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.participant-avatar {
  margin-right: 20px;
  color: #333333;
}

.participant-avatar img {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.participant-item p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
</style>
