<template>
  <div class="comment-input-container">
    <div class="comment-input-imagepreview" v-if="image">
      <img :src="image" height="180px"/>
      <div class="action">
        <span class="fe fe-x" v-on:click="onCloseImage"/>
      </div>
    </div>
    <div class="comment-input-reply" v-if="dataReply.id">
      <div class="name">{{ dataReply.name }}</div>
      <div class="message">{{ dataReply.message }}</div>
      <div class="action">
        <span class="fe fe-x" v-on:click="onCloseReply"/>
      </div>
    </div>
    <div class="comment-input-participant" v-if="openParticipant">
      <div class="user-item" v-for="user of tmpParticipants" :key="user.id" v-on:click="onSelectUser(user.fullname)">
        <div class="user-avatar">
          <img :src="user.avatar.url" v-if="user.avatar.url" />
          <span class="fe fe-user" style="font-size: 30px" v-else></span>
        </div>
        <p>{{ user.fullname }}</p>
      </div>
    </div>
    <div class="comment-input-body">
      <a-input
        ref="inputComment"
        v-model="comment"
        placeholder="Isi komentar"
        v-on:keyup.enter="onClickSend"
        v-on:keyup="onKeyupComment"
      />
      <a-button
        size="default"
        class="text-center btn ml-2"
        v-on:click="onClickPickFile">
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe-paperclip"/>
        </span>
      </a-button>
      <a-button
        size="default"
        class="text-center btn ml-2"
        v-on:click="onClickSend">
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe-send"/>
        </span>
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    participants: Array
  },
  data() {
    return {
      tmpParticipants: this.participants,
      comment: '',
      oldComment: '',
      file: null,
      image: null,
      openParticipant: false,
      startCursor: 0,
      endCursor: 0,
      dataReply: {
        id: null,
        name: null,
        image: null,
        message: null
      }
    }
  },
  methods: {
    onClickSend() {
      if(this.comment || this.file) {
        const data = {
          comment: this.comment,
          refid: this.dataReply.id,
          file: this.file
        }
        this.$emit('onSend', data)
        this.comment = '';
        this.onCloseImage();
        this.onCloseReply();
      }
    },

    onClickPickFile() {
      var input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => { 
        // getting a hold of the file reference
        var file = e.target.files[0];
        this.file = file;

        // setting up the reader
        var reader = new FileReader();
        reader.readAsDataURL(file);

        // here we tell the reader what to do when it's done reading...
        reader.onload = (readerEvent) => {
          var content = readerEvent.target.result; // this is the content!
          this.image = content
        }
      }
      input.click();
    },

    onKeyupComment(e) {
      const field = e.target;
      const value = field.value;
      const char = e.key;
      const keyCode = e.keyCode;
      this.endCursor = field.selectionStart;
      if(field.selectionStart == 1 && char == "@") {
        this.openParticipant = true;
        this.startCursor = field.selectionStart
      } else if(field.selectionStart > 1) {
        const prevChar = value[field.selectionStart-2];
        if(prevChar == " " && char == "@") {
          this.openParticipant = true;
          this.startCursor = field.selectionStart
        }
      }

      if(keyCode == 8 || keyCode == 46) {
        const deletedChar = this.oldComment[field.selectionStart];
        if(deletedChar == "@") {
          this.openParticipant = false;
          this.startCursor = 0
        }
      }

      if(this.openParticipant) {
        const search = this.comment.substring(this.startCursor, this.comment.length);
        const result = this.participants.filter(it => it.fullname.toLowerCase().includes(search.toLowerCase()));
        this.tmpParticipants = result;
      }
      
      this.oldComment = this.comment
    },

    onSelectUser(fullname) {
      const findUser = this.comment.substring(this.startCursor -1, this.endCursor);
      const result = this.comment.replace(findUser, `@${fullname}`);
      this.comment = result;
      this.$refs.inputComment.focus();
      this.openParticipant = false;
      this.startCursor = 0;
      this.endCursor = 0;
    },

    onCloseImage() {
      this.image = null;
      this.file = null;
    },

    onCloseReply() {
      this.dataReply.id = null
      this.dataReply.name = null
      this.dataReply.image = null
      this.dataReply.message = null
    },

    initReply(data) {
      this.dataReply.id = data.id
      this.dataReply.name = data.name
      this.dataReply.image = data.image
      this.dataReply.message = data.message
      this.$refs.inputComment.focus();
    }
  }
}
</script>
<style scoped>
.comment-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.comment-input-body {
  display: flex;
  flex-direction: row;
}

.comment-input-imagepreview {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 200px;
  margin: 20px 0;
  border: 1px solid #dddddd;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.comment-input-imagepreview .action {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.comment-input-reply {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.comment-input-reply .name {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.comment-input-reply .message {
  font-size: 14px;
  color: #333333;
  width: 80%;
}

.comment-input-reply .action {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.comment-input-participant {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.comment-input-participant .user-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.user-item p {
  font-size: 16px;
  color: #333333;
  margin: 0;
}

.user-item .user-avatar {
  margin-right: 20px;
  color: #333333;
}

.user-item .user-avatar img {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
</style>
